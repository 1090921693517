import React, { useEffect, useMemo } from 'react';

import { Col, Row } from 'react-grid-system';

import { useAppSelector, useDispatchCallback, useUserData } from '../../../hooks';
import { duplicateBike, removeBike } from '../../../actions/gewerblicherProgress';
import { HookedSelect, HookedTextInput, BikeIndex } from '../../../forms/shared/HookedInputs';
import { Labeled } from '../../../forms/shared/Common';
import { Anchor, CloseButton } from '@mantine/core';
import { BikeType } from '@/reducer/userData';

const rowStyle: React.CSSProperties = { padding: '3px' };

const optionalTooltip = 'Zur Berechnung optional, zum Antrag Pflicht';

const useDefaultBikeValue = <P extends keyof BikeType>(bikeKey: string, field: P, defaultValue: number | string) => {
  const [currentValue, onChange] = useUserData(field, bikeKey, (o) => o);
  useEffect(() => {
    console.log('currentValue', currentValue, defaultValue);

    if (currentValue !== null && currentValue !== undefined) return;
    console.log('setting default value');
    onChange(defaultValue);
  }, [currentValue, defaultValue, bikeKey, field, onChange]);
};

const SingleBikeRow = (props: { bikeKey: string; isDetailedInput?: boolean }) => {
  const { bikeKey, isDetailedInput } = props;

  const onDuplicateBike = useDispatchCallback(
    (dispatch, e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(duplicateBike(bikeKey));
    },
    [bikeKey]
  );
  const onRemoveBike = useDispatchCallback(
    (dispatch, e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(removeBike(bikeKey));
    },
    [bikeKey]
  );

  const devicemodes = useAppSelector((state) => state.ensfields.devicemodes);
  const [bikeSelection, defaultKey] = useMemo(() => {
    return [
      devicemodes.filter((mode) => mode.gewerblichSelectable).map((mode) => [mode.devkey, mode.name]),
      devicemodes.find((mode) => mode.default)?.devkey || devicemodes[0].devkey,
    ];
  }, [devicemodes]);

  useDefaultBikeValue(bikeKey, 'ageInYears', '0');

  return (
    <>
      <Row style={rowStyle}>
        <Col>
          <BikeIndex bikeKey={bikeKey} />
        </Col>
        <Col md={3}>
          <Labeled
            inputMode='number'
            control={HookedTextInput}
            placeholder
            bikeId={bikeKey}
            field={'price'}
            disabled={isDetailedInput}
          />
        </Col>
        <Col md={3}>
          <Labeled
            control={HookedTextInput}
            type={'number'}
            placeholder={'Alter (Jahre)'}
            bikeId={bikeKey}
            field={'ageInYears'}
            disabled={isDetailedInput}
          />
        </Col>
        <Col md={isDetailedInput ? 5 : 3}>
          <Labeled
            control={HookedSelect}
            fluid
            field={'devicemode'}
            bikeId={bikeKey}
            values={bikeSelection}
            defaultValue={defaultKey}
            disabled={isDetailedInput}
          />
        </Col>
        {isDetailedInput ? null : (
          <Col
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
            <Anchor
              href={'#'}
              onClick={onDuplicateBike}>
              Duplizieren
            </Anchor>
            <CloseButton
              aria-label='Löschen'
              onClick={onRemoveBike}
              title='Entfernen'
            />
          </Col>
        )}
      </Row>

      <Row style={{ marginTop: '1em' }}>
        <Col md={1} />
        <Col md={3}>
          <Labeled
            control={HookedTextInput}
            placeholder
            bikeId={bikeKey}
            optional={!isDetailedInput}
            tooltip={optionalTooltip}
            field={'bikeMarke'}
          />
        </Col>

        <Col md={3}>
          <Labeled
            control={HookedTextInput}
            placeholder
            bikeId={bikeKey}
            optional={!isDetailedInput}
            tooltip={optionalTooltip}
            field={'bikeTypeName'}
          />
        </Col>
        <Col md={3}>
          <Labeled
            control={HookedTextInput}
            placeholder
            bikeId={bikeKey}
            optional={true}
            field={'rahmennummer'}
          />
        </Col>
      </Row>
    </>
  );
};

export default SingleBikeRow;
