import { useAppSelector } from '../../../hooks';
import { UserDataOrBikeProp } from '../../../reducer/userData';

export const UserDataSwitch = ({
  field,
  targetValue,
  exclude,
  children
}: {
  field: UserDataOrBikeProp;
  [x: string]: any;
}) => {
  const userData = useAppSelector((state) => state.userData);

  let exit;

  if (userData[field]?.toString() == targetValue?.toString()) {
    exit = exclude;
  } else {
    exit = !exclude;
  }

  if (exit) {
    return null;
  }

  return children;
};
