import React, { useMemo } from 'react';
import Progressbar from '@unserkunde/enscompare-components/src/components/feedback/Progressbar';
import { Container } from '@mantine/core';

import EnsuranceList from 'forms/ensuranceList/EnsuranceList';
import Success from '@/forms/success/Success';
import AngebotFertig from 'forms/angebot/AngebotCompleted';
import { useAppSelector } from '@/hooks';
import { OverlayDisplay } from '../overlay/OverlayDisplay';
import { DefaultEnsProcessRenderer } from '../processDefinition/DefaultEnsProcessRenderer';
import { FluentProgressGewerblich } from './FluentProgressGewerblich';

const FluentRenderer = () => {
  const target = useAppSelector((state) => state.router);

  return (
    <DefaultEnsProcessRenderer
      target={target as keyof typeof FluentProgressGewerblich.processSteps}
      processDefinition={FluentProgressGewerblich}
    />
  );
};

const GewerblicherProgress = (props: { target: string }) => {
  const { target } = props;

  const overlayName = useAppSelector((state) => state.overlay.overlayName);

  let CurrentControl: React.ElementType = FluentRenderer;

  if (target === 'bikeInput') {
    CurrentControl = FluentRenderer;
  } else if (target === 'ensuranceList') {
    CurrentControl = EnsuranceList;
  } else if (target === 'detailedBikeInput') {
    CurrentControl = FluentRenderer;
  } else if (target === 'personalInfo') {
    CurrentControl = FluentRenderer;
  } else if (target === 'paymentInfo') {
    CurrentControl = FluentRenderer;
  } else if (target === 'zusammenfassung') {
    CurrentControl = FluentRenderer;
  } else if (target === 'order_success') {
    CurrentControl = Success;
  } else if (target === 'angebotfertig') {
    CurrentControl = AngebotFertig;
  }

  const progressProps = useMemo(
    () => ({
      progress: { value: 5 },
      title: 'Title',
    }),
    []
  );

  if (overlayName) CurrentControl = OverlayDisplay;

  return (
    <>
      <Container
        px='0'
        fluid
        data-testid='ens-gewerblicher-prozess'>
        <Progressbar {...progressProps} />
      </Container>
      <CurrentControl />
    </>
  );
};

export default GewerblicherProgress;
