import React, { useCallback } from 'react';

import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';

import { Button, Container, Grid, Title } from '@mantine/core';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { submitEnsuranceOverview } from '../../../actions/gewerblicherProgress';
import { HookedRadioSwitchRow } from '../../../forms/shared/HookedInputs';
import { UserDataSwitch } from '@/forms/shared/Switches/UserDataSwitch';

import { EntryQuestionContainer } from '@/forms/shared/DynamicEntryQuestion';
import { useInsuranceObjectName } from '@/forms/shared/InstanceNaming';
import { getUserInputEventDebugInfo } from '@/actions/form';
import { useIsInEnsProcessContext } from '@/processes/processDefinition/DefaultEnsProcessContext';

const YesNo: [string, string | number][] = [
  ['Ja', 'true'],
  ['Nein', 'false'],
];

const YesReverse: [string, string | number][] = [
  ['Ja', 'false'],
  ['Nein', 'true'],
];

function ButtonRow() {
  const dispatch = useAppDispatch();
  const onContinue = useCallback(() => dispatch(submitEnsuranceOverview()), []);

  const onResetDeviceMode = useCallback(
    () => dispatch({ type: 'DEVICEMODE_SELECTED', devkey: '', ...getUserInputEventDebugInfo() }),
    [dispatch]
  );

  const canSelectDeviceMode = useAppSelector((state) => state.devicemode.isselect);

  const insuranceObjectName = useInsuranceObjectName();

  const hideButtons = useIsInEnsProcessContext();

  if (hideButtons) return null;

  return (
    <Container>
      <Grid align='center'>
        <Grid.Col
          span={12}
          sm={6}>
          {!canSelectDeviceMode ? null : (
            <ButtonLink
              leftIcon={<FaAngleLeft />}
              onClick={onResetDeviceMode}>
              Zurück
            </ButtonLink>
          )}
        </Grid.Col>
        <Grid.Col
          span={12}
          sm={6}>
          <Button
            color='secondary'
            fullWidth
            onClick={onContinue}>
            {insuranceObjectName} hinzufügen
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

const EnsuranceOverview = () => {
  return (
    <Container data-testid='ens-gewerblicher-prozess-entryquestions'>
      <EntryQuestionContainer fluid>
        <Title
          order={2}
          mt={'12px !important'}>
          Zuerst einige Fragen zu Ihnen
        </Title>

        <HookedRadioSwitchRow
          title={'Nutzen Sie die Bikes gewerblich?'}
          defaultValue={false}
          field={'privateUse'}
          values={YesReverse}
        />
        <HookedRadioSwitchRow
          values={YesNo}
          title={'Verleihen Sie die Bikes entgeltlich?'}
          defaultValue={false}
          field={'gewerblich_bikeRental'}
        />

        <UserDataSwitch
          field={'gewerblich_bikeRental'}
          targetValue={true}>
          <HookedRadioSwitchRow
            values={YesNo}
            title={'Sind Sie ein Fahrradhändler oder eine Werkstatt?'}
            defaultValue={false}
            field={'gewerblich_dealerOrWorkshop'}
          />
          <HookedRadioSwitchRow
            values={YesNo}
            title={'Sind Sie ein Beherbungsbetrieb?'}
            defaultValue={false}
            field={'gewerblich_accommodation'}
          />
        </UserDataSwitch>

        <HookedRadioSwitchRow
          values={YesNo}
          title={'Betreiben Sie Bike Sharing?'}
          field={'gewerblich_bikesharing'}
          defaultValue={false}
        />
        <HookedRadioSwitchRow
          values={YesNo}
          title={'Nutzen Sie das Bike für Liefer- oder Kurier Fahrten?'}
          field={'gewerblich_delivery'}
          defaultValue={false}
        />
        <HookedRadioSwitchRow
          values={YesNo}
          title={'Transportieren Sie Personen damit?'}
          field={'gewerblich_personTransport'}
          defaultValue={false}
        />
        <HookedRadioSwitchRow
          values={YesNo}
          title={'Sind Sie vorsteuerabzugsberechtigt'}
          field={'gewerblich_deductInputTax'}
          defaultValue={true}
        />
      </EntryQuestionContainer>

      <ButtonRow />
    </Container>
  );
};

export default EnsuranceOverview;
