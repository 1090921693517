import Papaparse from 'papaparse';
import FileSaver from 'file-saver';
import fileDialog from 'file-dialog';
import { bikeStartValue, BikeType } from '../reducer/userData';

const nameof = <T>(name: keyof T) => name;

const bikeFields = {
  preis: 'price',
  modell: 'bikeTypeName',
  hersteller: 'bikeMarke',
  Rahmennummer: 'rahmennummer',
  "Bike-Typ ('ebike', 'bike', 'rikscha', 'erikscha', 'spedelec')": 'devicemode',
  'Alter in Jahren': 'ageInYears'
};

export const getBikeCsv = () => {
  return (_, getState) => {
    const bikes = getState().userData.bikes;
    const entries = Object.keys(bikes).map((bikeKey) => {
      const row = {};
      Object.keys(bikeFields).forEach((bikeField) => {
        row[bikeField] = bikes[bikeKey][bikeFields[bikeField]];
      });
      return row;
    });

    const csv = Papaparse.unparse(entries, {
      delimiter: ';',
      encoding: 'ISO-8859-1'
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=ISO-8859-1' });
    FileSaver.saveAs(blob, 'Bike Import CSV.csv');
  };
};

export const importBikeCsv = () => {
  return async (dispatch) => {
    const files = await fileDialog({ accept: 'text/csv' });

    if (files.length === 0) {
      return;
    }

    const fileContent = await files[0].text();
    const content = Papaparse.parse(fileContent, {
      encoding: 'ISO-8859-1',
      header: true,
      skipEmptyLines: true
    });

    const bikes = content?.data?.map((row) => {
      const bike = { ...bikeStartValue };
      Object.keys(row).forEach((rowKey) => {
        const keyName =
          Object.keys(bikeFields).find((objKey) => objKey.toLowerCase() === rowKey.toLowerCase()) || rowKey;
        bike[bikeFields[keyName]] = row[rowKey];

        if (bikeFields[keyName] === nameof<BikeType>('devicemode')) {
          bike[bikeFields[keyName]] = bike[bikeFields[keyName]].toLowerCase();
        }

        if (bikeFields[keyName] === 'price') {
          let rawValue = bike[bikeFields[keyName]];

          if (rawValue.includes(',') && rawValue.includes('.')) {
            if (rawValue.indexOf(',') > rawValue.indexOf('.')) {
              rawValue = rawValue.replace('.', '');
            } else {
              rawValue = rawValue.replace(',', '');
            }
          }

          rawValue = rawValue.replace(',', '.');

          bike[bikeFields[keyName]] = parseFloat(bike[bikeFields[keyName]]) || rawValue;
        }
      });
      return bike;
    });

    dispatch({ type: 'BIKE_OVERRIDES', bikes });
  };
};
