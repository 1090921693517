import { navigateTo, submitGeneral } from './progress';
import requestOffers from './data/requestOffers';
import { getValidateBikes } from '@/actions/validation/validationGewerblich';

export const submitEnsuranceOverview = () => navigateTo('bikeInput');

export const duplicateBike = (bikeKey) => ({ type: 'DUPLICATE_BIKE', bikeKey });

export const submitBikeInput = () =>
  submitGeneral(getValidateBikes(false), null, (dispatch: AppDispatch) => {
    dispatch(requestOffers());
  });

export const submitDetailedBikeInput = () => submitGeneral(getValidateBikes(true), 'personalInfo');

export const removeBike = (bikeKey) => ({
  type: 'REMOVE_BIKE',
  bikeId: bikeKey
});
