import React, { useCallback } from 'react';

import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';

import { Button, Container, Divider, Grid, Space } from '@mantine/core';
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { navigateTo, backToEnsList } from '../../../actions/progress';
import { submitBikeInput, submitDetailedBikeInput } from '../../../actions/gewerblicherProgress';
import { HrRow } from '../../../forms/shared/style/components';
import CsvInput from './CsvInput';
import SingleBikeRow from './SingleBikeRow';
import { useEnsOverviewStyles } from '@/forms/OverviewControls/EnsuranceOverview';
import { useIsInEnsProcessContext } from '@/processes/processDefinition/DefaultEnsProcessContext';

const useIsDetailedInput = () => useAppSelector((state) => state.router === 'detailedBikeInput');

function TopButtonRow() {
  const dispatch = useAppDispatch();
  const isDetailedInput = useIsDetailedInput();
  const onGoBack = useCallback(() => {
    dispatch(isDetailedInput ? backToEnsList() : navigateTo(''));
  }, []);

  const { classes } = useEnsOverviewStyles();

  return (
    <Container className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={<FaAngleLeft />}
        onClick={onGoBack}>
        Zurück
      </ButtonLink>
    </Container>
  );
}

function ButtomButtonRow() {
  const dispatch = useAppDispatch();
  const isDetailedInput = useIsDetailedInput();

  const onContinue = useCallback(() => {
    dispatch(isDetailedInput ? submitDetailedBikeInput() : submitBikeInput());
  }, [isDetailedInput]);
  const onGoBack = useCallback(() => {
    dispatch(isDetailedInput ? backToEnsList() : navigateTo(''));
  }, []);

  return (
    <Container px={0}>
      <Grid align='center'>
        <Grid.Col
          xs={12}
          sm={6}>
          <ButtonLink
            leftIcon={<FaAngleLeft />}
            onClick={onGoBack}>
            Zurück
          </ButtonLink>
        </Grid.Col>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            color='secondary'
            fullWidth
            onClick={onContinue}>
            {!isDetailedInput ? <>Angebote ansehen&nbsp;&nbsp;</> : <>Persönliche Angaben</>}
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
const BikeInputPage = () => {
  const bikeKeys = useAppSelector((state) => Object.keys(state.userData.bikes));
  const isDetailedInput = useIsDetailedInput();

  const hideButtonRow = useIsInEnsProcessContext();

  return (
    <Container px={0}>
      {!hideButtonRow && <TopButtonRow />}

      {bikeKeys.map((key, index) => (
        <React.Fragment key={key}>
          <SingleBikeRow
            isDetailedInput={isDetailedInput}
            key={key}
            bikeKey={key}
          />
          {index < bikeKeys.length - 1 ? <HrRow className='large' /> : null}
        </React.Fragment>
      ))}
      {isDetailedInput ? null : (
        <Container>
          <Divider my={'sm'} />
          <CsvInput />
          <Divider my={'sm'} />
        </Container>
      )}

      <Space h={isDetailedInput ? 'xl' : 'sm'} />
      {!hideButtonRow && <ButtomButtonRow />}
    </Container>
  );
};

export default BikeInputPage;
