import React, { useCallback } from 'react';
import { Col, Row } from 'react-grid-system';
import { getBikeCsv, importBikeCsv } from '../../../actions/CsvBikeImport';
import { useAppDispatch } from '../../../hooks';
import { LinkButton } from '../../../forms/shared/style/components';

export default () => {
  const dispatch = useAppDispatch();
  const onGetBikeCsv = useCallback(() => dispatch(getBikeCsv()), [dispatch]);
  const onImportBikeCsv = useCallback(() => dispatch(importBikeCsv()), [dispatch]);

  return (
    <Row>
      <Col style={{ display: 'flex', flexDirection: 'column' }}>
        Wollen Sie es lieber per CSV befüllen?
        <span style={{ display: 'block', marginTop: '1em' }}>
          Dann <LinkButton onClick={onGetBikeCsv}>laden Sie hier die CSV herunter</LinkButton>
        </span>
        <span>
          Und <LinkButton onClick={onImportBikeCsv}>hier ausgefüllt wieder hoch</LinkButton>
        </span>
      </Col>
    </Row>
  );
};
