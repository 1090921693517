import { createEnsProcessDefinition, EnsProcessDefinitionDefault } from '@/processes/processDefinition/EnsProcess';

import { backToEnsList, submitZusammenfassung } from '@/actions/progress';
import {
  validatePayment,
  validatePersonal,
  validatePersonalContact,
  validateZusammenfassung,
} from '@/actions/validation/validation';
import BikeInputPage from './BikeInput/BikeInputPage';
import EnsuranceOverview from './OverviewControls/EnsuranceOverview';
import { submitBikeInput, submitDetailedBikeInput, submitEnsuranceOverview } from '@/actions/gewerblicherProgress';
import PaymentInput from '@/forms/checkout/inputs/PaymentInput';
import PersonalInput from '@/forms/checkout/inputs/PersonalInput';
import Zusammenfassung from '@/forms/zusammenfassung/Zusammenfassung';
import { selectDevMode } from '@/actions/devmode';
import PersonalContactInput from '@/forms/checkout/inputs/PersonContactInput';
import React from 'react';

export const FluentProgressGewerblich: EnsProcessDefinitionDefault = createEnsProcessDefinition({
  processSteps: {
    '': {
      component: EnsuranceOverview,
      validate: () => [],
      stepperTitle: '',
      title: 'Eingangsfragen',
      useFluentProgress: true,

      prevStepAction: (dispatch) => dispatch(selectDevMode('', true)),

      nextStepAction: (dispatch) => dispatch(submitEnsuranceOverview()),
    },
    bikeInput: {
      validate: () => [],
      stepperTitle: 'Antrag',
      title: 'Deine Geräte',
      component: BikeInputPage,
      useFluentProgress: true,
      previosStep: '',

      nextStepLabel: 'Angebote ansehen',
      nextStepAction: (dispatch) => dispatch(submitBikeInput()),
    },
    ensuranceList: {
      validate: () => [],
      stepperTitle: '',
      title: 'Angebote',
      component: undefined,
      useFluentProgress: true,
    },
    detailedBikeInput: {
      validate: () => [],
      stepperTitle: 'Antrag',
      title: 'Deine Gerätedetails',
      component: BikeInputPage,
      useFluentProgress: true,

      prevStepAction: (dispatch) => dispatch(backToEnsList()),

      nextStepLabel: 'Persönliche Angaben',
      nextStepAction: (dispatch) => dispatch(submitDetailedBikeInput()),
    },
    personalInfo: {
      validate: [validatePersonal, validatePersonalContact],
      component: () => (
        <>
          <PersonalContactInput />
          <PersonalInput />
        </>
      ),
      stepperTitle: 'Antrag',
      title: 'Persönliche Informationen',
      useFluentProgress: true,
    },
    paymentInfo: {
      validate: validatePayment,
      stepperTitle: 'Antrag',
      title: 'Zahlungsdaten',
      component: PaymentInput,
      useFluentProgress: true,
    },
    zusammenfassung: {
      validate: validateZusammenfassung,
      stepperTitle: 'Zusammenfassung',
      title: 'Zusammenfassung',
      component: Zusammenfassung,
      useFluentProgress: true,
      //fluentPreventBack: true,
      nextStepLabel: 'Zahlungspflichtig bestellen',
      nextStepAction: (dispatch) => dispatch(submitZusammenfassung()),
    },
  },
});
